<template>
  <!-- TPC异常 -->
    <div style="padding:16px" v-loading.fullscreen.lock="fullscreenLoading">
      <el-card>
        <el-form
          ref="queryForm"
          :model="queryParams"
          size="small"
          label-width="90px"
          @submit.native.prevent
        >
          <div class="formModel">
            <el-form-item label="工单号" prop="ticket_id">
              <el-input
                class="formItem"
                v-model="queryParams.ticket_id"
                placeholder="请输入工单号"
                clearable
                @keyup.enter.native="handleQuery"
              />
            </el-form-item>
            <!-- <el-form-item label="工单名称" prop="processTitle">
              <el-input
                class="formItem"
                v-model="queryParams.processTitle"
                placeholder="请输入工单名称"
                clearable
                @keyup.enter.native="handleQuery"
              />
            </el-form-item> -->
            <el-form-item label="一级机房" prop="idcp_name">
              <el-cascader
                v-model="queryParams.idcp_name"
                :options="depOptions"
                :props="{
                  multiple: true,
                  expandTrigger: 'hover',
                  label: 'dept_name',
                  value: 'dept_name',
                  children: 'children',
                }"
                collapse-tags
                clearable
              ></el-cascader>
            </el-form-item>
            <el-form-item label="工单状态" prop="status">
              <el-select
                v-model="queryParams.status"
                placeholder="请选择工单状态"
                clearable
                @keyup.enter.native="handleQuery"
              >
                <el-option
                  v-for="item in orderStatusOptions"
                  :key="item.id"
                  :label="item.value"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="超时状态" prop="timeout_status">
              <el-select
                v-model="queryParams.timeout_status"
                placeholder="请选择超时状态"
                clearable
                @keyup.enter.native="handleQuery"
              >
                <el-option
                  v-for="item in timeoutStatusOptions"
                  :key="item.id"
                  :label="item.value"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="结单时间">
              <el-date-picker
                v-model="statementTimeRange"
                type="daterange"
                class="formItem"
                value-format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                clearable
                @keyup.enter.native="handleQuery"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="创建时间" prop="createdTimeRange">
              <el-date-picker
                v-model="createdTimeRange"
                type="daterange"
                class="formItem"
                value-format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                clearable
                @keyup.enter.native="handleQuery"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="是否催办" prop="reminders">
              <el-select
                v-model="queryParams.reminders"
                placeholder="请选择"
                clearable
                @keyup.enter.native="handleQuery"
              >
                <el-option
                  v-for="item in remindOptions"
                  :key="item.id"
                  :label="item.value"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <div class="formItem btns">
              <el-button type="primary" size="small" icon="el-icon-search" @click="handleQuery"
                >搜索
              </el-button>
              <el-button icon="el-icon-refresh" size="small" @click="handleFormRest('queryForm')"
                >重置
              </el-button>
            </div>
          </div>
        </el-form>
      </el-card>
      <el-card style="margin-top: 15px; padding-bottom: 20px">
        <el-button icon="el-icon-download" size="small" type="primary" @click="handleExport"
          >导出
        </el-button>
        <el-table v-loading="loading" :data="list" style="width: 100%; margin-top: 20px">
          <el-table-column property="ticket_id" width="160" label="工单号">
            <template slot-scope="scope">
              <span class="ticketId" @click="getDetail(scope.row)">{{ scope.row.ticket_id }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column property="processTitle" width="160" label="工单名称"></el-table-column> -->
          <el-table-column property="idcp_name" label="机房"></el-table-column>
          <el-table-column property="status" label="工单状态"></el-table-column>
          <el-table-column property="handle_users" label="当前处理人"></el-table-column>
          <el-table-column property="ola_time" label="截止时间"></el-table-column>
          <el-table-column property="countdown" label="倒计时"></el-table-column>
          <el-table-column property="timeout_status" label="超时状态">
            <template slot-scope="scope">
              <span
                :style="
                  scope.row.timeout_status && scope.row.timeout_status.includes('临近超时')
                    ? 'color:#f49b0d;font-weight:bold;'
                    : scope.row.timeout_status && scope.row.timeout_status.includes('已超时')
                    ? 'color:#f40d0e;font-weight:bold;'
                    : 'color:#1381e6;font-weight:bold;'
                "
                >{{ scope.row.timeout_status }}</span
              >
            </template>
          </el-table-column>
          <el-table-column label="催办记录">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="mini"
                @click="getRecord(scope.row)"
                v-if="scope.row.isRemind"
                >查看</el-button
              >
            </template>
          </el-table-column>
          <el-table-column property="end_time" label="结单时间"></el-table-column>
          <el-table-column property="create_time" label="创建时间"></el-table-column>
          <el-table-column fixed="right" align="left" label="操作" width="100">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="mini"
                @click="remind(scope.row)"
                v-if="scope.row.status == '处理中'"
                >催办</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          :current-page.sync="pageParams.page"
          layout="total, sizes, prev, pager, next, jumper"
          :page-sizes="[25, 50, 100]"
          :page-size.sync="pageParams.limit"
          :total="total"
          style="margin-top: 20px"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-card>
      <!-- 查看催办记录 -->
      <el-dialog
        title="催办记录"
        :visible.sync="recordVisible"
        width="1000px"
        :before-close="recordClose"
        center
      >
        <el-table :data="remindList">
          <el-table-column property="ticketId" label="工单"></el-table-column>
          <el-table-column property="remindStaff" label="催办对象"></el-table-column>
          <el-table-column property="remindMessage" label="催办内容"></el-table-column>
          <el-table-column property="remindPerson" label="催办人"></el-table-column>
          <el-table-column property="createTime" label="催办时间"></el-table-column>
        </el-table>
        <span slot="footer" class="dialog-footer">
          <el-button @click="recordVisible = false">关 闭</el-button>
        </span>
      </el-dialog>
      <!-- 催办 -->
      <el-dialog
        title="催办通知"
        :visible.sync="remindVisible"
        width="700px"
        :before-close="remindClose"
        center
      >
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="工单单号：" prop="ticketId">
            <span style="font-size:14px;color:#333">{{ ruleForm.ticketId }}</span>
          </el-form-item>
          <!-- <el-form-item label="工单名称：" prop="name">
            <span style="font-size:14px;color:#333">{{ ruleForm.processTitle }}</span>
          </el-form-item> -->
          <el-form-item label="通知对象：" prop="remindStaff">
            <el-checkbox-group v-model="ruleForm.remindStaff">
              <el-checkbox label="1">当前处理人</el-checkbox>
              <!-- <el-checkbox label="2">区管</el-checkbox> -->
            </el-checkbox-group>
            <span>
                催区管
            </span>
              <el-select v-model="selectedOption" placeholder="请选择" clearable>
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
          </el-form-item>
          <el-form-item label="通知内容：" prop="remindMessage">
            <el-input
              type="textarea"
              placeholder="请输入"
              maxlength="1000"
              v-model="ruleForm.remindMessage"
              rows="3"
            ></el-input>
          </el-form-item>
        </el-form>
        <div style="padding-left:20px">
          <h4>快捷语句：</h4>
          <div
            class="item-shortcut-statement"
            v-for="(item, index) in shortcutStatement"
            :key="index"
          >
            <span>{{ item }}</span>
            <el-button type="primary" size="mini" @click="add(item)">添加</el-button>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="submitForm('ruleForm')">提 交</el-button>
          <el-button @click="remindVisible = false">取 消</el-button>
        </span>
      </el-dialog>
    </div>
  </template>
  <script>
  import { saveAs } from 'file-saver';
  import { tpcList, deptTree, getRemindList, exportTPC, remind } from '@/api/monitor';
  import {removeEmptyStringFields} from '@/utils/common'
  export default {
    name: 'workOrderList',
    data() {
      return {
        shortcutStatement: [
          '该工单所处流程已临近超时，请尽快处理',
          '该工单所处流程已超时，请尽快处理',
        ],
        ruleForm: {
          ticketType:4,
          ticketId: '', //工单号
          // processTitle: '', //工单名称
          remindMessage: '', //通知内容
          remindStaff: [], //通知对象
        },
        rules: {
          remindStaff: [
          {
              validator: this.validateRemindStaff,
              trigger: 'change'
          },
          ],
          remindMessage: [{ required: true, message: '请填写通知内容', trigger: 'blur' }],
        },
        remindVisible: false, //催办弹窗
        fullscreenLoading: false,
        remindList: [], //催办记录数据
        recordVisible: false, //催办记录弹窗
        loading: false,
        total: 0,
        list: [],
        queryParams: {
          ticket_id: '', //工单号
          // processTitle: '', //工单名称
          idcp_name: [], //一级机房
          status: '处理中', //工单状态
          timeout_status: '', //超时状态
          over_stat_datetime: '', //结单开始时间
          over_end_datetime: '', //结单结束时间
          create_start_datetime: '', //创建开始时间
          create_end_datetime: '', //创建结束时间
          reminders: '', //是否催办
        },
        pageParams: {
          page: 1,
          limit: 25,
        },
        statementTimeRange: [], // 结单时间
        createdTimeRange: [], //创建时间
        orderStatusOptions: [
          //工单状态数据
          {
            id: 1,
            value: '处理中',
          },
          {
            id: 2,
            value: '已结单',
          },
        ],
        timeoutStatusOptions: [
          //超时状态
          {
            id: 1,
            value: '临近超时',
          },
          {
            id: 2,
            value: '已超时',
          },
          {
            id: 3,
            value: '未超时',
          },
          {
            id: 4,
            value: '尾单',
          },
        ],
        remindOptions: [
          //是否催办数据
          {
            id: 1,
            value: '已催办',
          },
          {
            id: 0,
            value: '未催办',
          },
        ],
        depOptions: [], //一级机房数据
        // 催办区管配置
        selectedOption: '', // 选中的值
        options: [
          { value: '李仕彬', label: '李仕彬' },
          { value: '彭思源', label: '彭思源' },
          { value: '叶军', label: '叶军' },
          { value: '祁荣川', label: '祁荣川' },
        ]
      };
    },
    created() {
      if (sessionStorage.getItem('tpc_params')) {
        this.queryParams = JSON.parse(sessionStorage.getItem('tpc_params'));
        if(this.queryParams.create_start_datetime){
          this.createdTimeRange = [this.queryParams.create_start_datetime,this.queryParams.create_end_datetime]
        }
      }
      this.deptTree();
      this.getList();
    },
    methods: {
      //添加快捷语句
      add(item) {
        this.ruleForm.remindMessage = item;
      },
      //关闭催办弹窗
      remindClose() {
        this.$nextTick(function(){
          this.$refs['ruleForm'].resetFields();
        })
        this.remindVisible = false;
      },
      validateRemindStaff(rule, value, callback) {
        if (!value.length && !this.selectedOption) {
          callback(new Error('当前处理人和主管不能同时为空'));
        } else {
          callback();
        }
      },
      //点击催办
      remind(row) {
        this.ruleForm.ticketId = row.ticket_id;
        // this.ruleForm.processTitle = row.processTitle;
        this.remindVisible = true;
      },
      //提交催办
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let data = JSON.stringify(this.ruleForm);
            let dataNew = JSON.parse(data);
            dataNew.remindStaff = {};
            let result = this.list.find((obj) => obj.ticket_id == dataNew.ticketId);
            if (this.ruleForm.remindStaff.includes('1')) {
              dataNew.remindStaff.currentProcessUser = result.handle_users || ''; //当前处理人
            }
            if (this.ruleForm.remindStaff.includes('2')) {
              dataNew.remindStaff.districtManager = result.idcp_name || ''; //区域（取机房字段）
            }

            if (this.selectedOption){
            dataNew.remindStaff.management = this.selectedOption || ''; //区管名
            }
            dataNew.ticketType = 4;//TPC异常
            this.fullscreenLoading = true;
            remind(dataNew)
              .then((res) => {
                //{"code":200,"msg":"成功"}
                if (res.data.code == 200) {
                  this.$message.success(res.data.msg);
                  this.fullscreenLoading = false;
                  this.remindVisible = false;
                  this.getList();
                }else{
                  this.$message.error(res.data.msg)
                  this.fullscreenLoading = false;
                }
              })
              .catch(() => {
                this.fullscreenLoading = false;
              });
          } else {
            return false;
          }
        });
      },
      //导出
      handleExport() {
        this.fullscreenLoading = true;
        exportTPC(this.formattingParams())
          .then((res) => {
            const blob = new Blob([res.data]);
            saveAs(blob, `TPC异常_${new Date().getTime()}.xlsx`);
            this.fullscreenLoading = false;
          })
          .catch(() => {
            this.fullscreenLoading = false;
          });
      },
      //点击查看催办记录
      getRecord(row) {
        this.fullscreenLoading = true;
        getRemindList({ ticketId: row.ticketId,ticketType:4 })
          .then((res) => {
            if (res.data.code == 0) {
              this.remindList = res.data.data;
              this.recordVisible = true;
              this.fullscreenLoading = false;
            } else {
              this.$message.error(res.data.msg);
              this.fullscreenLoading = false;
            }
          })
          .catch(() => {
            this.fullscreenLoading = false;
          });
      },
      recordClose() {
        this.recordVisible = false;
      },
      //一级机房数据
      deptTree() {
        deptTree().then((res) => {
          this.depOptions = res?.data?.data;
        });
      },
      //查看详情
      getDetail(row) {
        this.$router.push({
          path: '/appManage/monitor/TPC_Abnormal/detail',
          query: {
            ticket_id: row.ticket_id,
          },
        });
      },
      //格式化参数
      formattingParams(){
        let params = JSON.stringify(this.queryParams);
        let paramsNew = JSON.parse(params);
        paramsNew.idcp_name = this.queryParams.idcp_name.map((row) => row.at(-1)) || []; //一级机房
        if (this.statementTimeRange.length) {
          paramsNew.over_stat_datetime = `${this.statementTimeRange[0]} 00:00:00`; //结单开始时间
          paramsNew.over_end_datetime = `${this.statementTimeRange[1]} 23:59:59`; //结单结束时间
        }
        if (this.createdTimeRange.length) {
          paramsNew.create_start_datetime = `${this.createdTimeRange[0]} 00:00:00`; //创建开始时间
          paramsNew.create_end_datetime = `${this.createdTimeRange[1]} 23:59:59`; //创建截止时间
        }
        return removeEmptyStringFields(paramsNew)
      },
      //列表
      getList() {
        this.loading = true;
        tpcList({ ...this.pageParams, ...this.formattingParams() })
          .then((res) => {
            if (res.data.code == 0) {
              this.list = res.data.data;
              this.total = res.data.count;
              this.loading = false;
            } else {
              this.$message.error(res.data.msg);
              this.loading = false;
            }
          })
          .catch(() => {
            this.loading = false;
          });
      },
      /** 搜索按钮操作 */
      handleQuery() {
        this.pageParams.page = 1;
        this.pageParams.limit = 25;
        this.getList();
      },
      // 重置
      handleFormRest(formName) {
        sessionStorage.removeItem('tpc_params');
        this.queryParams = {
          ticket_id: '', //工单号
          // processTitle: '', //工单名称
          idcp_name: [], //一级机房
          status: '处理中', //工单状态
          timeout_status: '', //超时状态
          over_stat_datetime: '', //结单开始时间
          over_end_datetime: '', //结单结束时间
          create_start_datetime: '', //创建开始时间
          create_end_datetime: '', //创建结束时间
          reminders: '', //是否催办
        };
        this.statementTimeRange = [];
        this.createdTimeRange = [];
        this.handleQuery();
      },
      // 监听每页条数改变
      handleSizeChange(newSize) {
        this.pageParams.limit = newSize;
        this.pageParams.page = 1;
        this.getList();
      },
      // 监听当前页面变化
      handleCurrentChange(newPage) {
        this.pageParams.page = newPage;
        this.getList();
      },
    },
  };
  </script>
  <style lang="scss" scoped>
  ::v-deep .demo-ruleForm .el-form-item {
    margin-bottom: 16px !important;
  }
  .item-shortcut-statement {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    span {
      padding-right: 20px;
      font-size: 14px;
      line-height: 18px;
      color: #333;
    }
  }
  .ticketId {
    color: #1890ff;
    cursor: pointer;
  }
  .standard-blue-link {
    color: #1890ff;
    text-decoration: none;
  }
  .formModel {
    display: flex;
    flex-flow: row wrap;
  }
  .formItem {
    // width: 175px !important;
    margin-bottom: 20px;
  }
  ::v-deep .el-form-item--small.el-form-item {
    margin-bottom: 0px;
  }
  ::v-deep .el-card__body {
    padding: 15px 20px 0 20px !important;
  }
  .btns {
    margin-left: 20px;
  }
  </style>
  